import axios from "axios";
import { RemoveAspas } from "../functions/utils";
import { useDispatch } from "react-redux";
import { Show_Alert, Hidden_Alert } from "../store/actions/alert";
import { store } from "../store";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}/v2`,
});

let pendingRequests = {};

//Adicionando token do user em todas os request dentro do app
api.interceptors.request.use((config) => {
  const key = config.method + config.url + JSON.stringify(config.data);
  if (config.method !== "get" && !config.url.includes("/images")) {
    if (pendingRequests[key]) {
      return new Promise(() => {});
    }
  }
  pendingRequests[key] = true;
  try {
    const token = localStorage.getItem("preveni_token_v2");
    if (token) {
      config.headers.Authorization = `Bearer ${RemoveAspas(token)}`;
      config.headers.common["Access-Control-Allow-Origin"] = "*";
    }

    return config;
  } catch (error) {
    console.log("Error axios " + error);
  }
});

api.interceptors.response.use(
  (response) => {
    const config = response.config;
    if (config.method !== "get" && !config.url.includes("/images")) {
      const key = config.method + config.url + config.data;
      delete pendingRequests[key];
    }
    return response.data.data !== undefined
      ? {
          data: response.data.data,
          status: response.status,
        }
      : response;
  },
  (error) => {
    const config = error.config;
    if (error?.response?.data?.logout) {
      localStorage.clear();
      window.location.href = "/";
    }
    if (error?.response?.data) {
      let errorData = error.response.data;
      if (errorData?.error?.hasMfaValidatorCorrect == false) {
        window.location.href = "/Otp/Cadastro";
      }
      if (errorData?.error?.notifyPlanIsNotActive) {
        window.location.href = "/Planos/Selecionar";
      }
      if (errorData?.error?.userNotActive) {
        window.location.href =
          "/AtivarConta/" + encodeURIComponent(errorData.error.activationToken);
      }
    }
    if (config && config?.method !== "get") {
      const key = config.method + config.url + config.data;
      console.log(pendingRequests, key);
      delete pendingRequests[key];
    }
    return Promise.reject(error);
  }
);

function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export default api;
