import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import SimpleReactLightbox from "simple-react-lightbox";

import { isAuthenticated, hasHospital } from "../src/functions/utils";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import AlertBox from "./components/AlertBox/AlertBox";
import "../src/styles/global.css";

//Customer (Login Off)
import Login from "./pages/Customer/Login/Login";
import EsqueceuSenha from "./pages/Customer/EsqueceuSenha/EsqueceuSenha";
import AlterarSenha from "./pages/Customer/AlterarSenha/AlterarSenha";
import DefinirSenha from "./pages/Customer/DefinirSenha/DefinirSenha";
import AtivarConta from "./pages/Customer/AtivarConta/AtivarConta";

//(Login On)

//Home
import Home from "./pages/Home/Home/Home";
import NotificarLesoes from "./pages/Home/NotificarLesoes/NotificarLesoes";
import MudancaDecubito from "./pages/Home/MudancaDecubito/MudancaDecubito";
import MudancaDecubitoNew from "./pages/Home/MudancaDecubitoNew/MudancaDecubitoNew";
import Vinculacao from "./pages/Home/Vinculacao/Vinculacao";
import Notificacoes from "./pages/Home/Notificacoes/Notificacoes";
import Conquistas from "./pages/Home/Conquistas/Conquistas";
import Ranking from "./pages/Home/Ranking/Ranking";
import AvaliacaoRisco from "./pages/Home/Home/AvaliacaoRisco";

import MinhaConta from "./pages/Configuracoes/MinhaConta";

//Pacientes
import Pacientes from "./pages/Pacientes/Pacientes";
import PerfilPaciente from "./pages/Pacientes/DetalhesPaciente/PerfilPaciente/PerfilPaciente";
import EvolucaoLesao from "./pages/Pacientes/Lesao/EvolucaoLesao";

// Descubra
import Descubra from "./pages/Descubra/Descubra";
import Comunicados from "./pages/Descubra/Comunicados/Comunicados";
import Protocolos from "./pages/Descubra/Protocolos/Protocolos";
import Bundle from "./pages/Descubra/Bundle/Bundle";
import Detalhes from "./pages/Descubra/Detalhes/Detalhes";

//Relatorios
import Relatorios from "./pages/Relatorios/Relatorios";

//Otp
import CadastrarOtp from "./pages/Customer/CadastrarOtp/CadastrarOtp";
import ValidarOtp from "./pages/Customer/ValidarOtp/ValidarOtp";
import Selecionar from "./pages/Planos/Selecionar/Selecionar";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

/*
Toda rota que precisa estar logado pra acessar
criar usando PrivateRoute 
*/
function Routes() {
  return (
    <div className="app">
      <SimpleReactLightbox>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <main>
                <AlertBox />
                <Switch>
                  <Route exact path="/" component={Login} />
                  <Route exact path="/EsqueciSenha" component={EsqueceuSenha} />
                  <Route exact path="/AlterarSenha" component={AlterarSenha} />
                  <Route
                    exact
                    path="/DefinirSenha/:hash?"
                    component={DefinirSenha}
                  />
                  <Route
                    exact
                    path="/AtivarConta/:hash?"
                    component={AtivarConta}
                  />
                  <PrivateRoute exact path="/Home" component={Home} />
                  <PrivateRoute
                    exact
                    path="/Home/AvaliacaoRisco"
                    component={AvaliacaoRisco}
                  />
                  <PrivateRoute
                    exact
                    path="/Home/NotificarLesoes"
                    component={NotificarLesoes}
                  />
                  {/* <PrivateRoute
                    exact
                    path="/Home/MudancaDecubito"
                    component={MudancaDecubito}
                  /> */}
                  <PrivateRoute
                    exact
                    path="/MapaDecubito"
                    component={MudancaDecubitoNew}
                  />
                  <PrivateRoute
                    exact
                    path="/Home/Vinculacao"
                    component={Vinculacao}
                  />
                  <PrivateRoute
                    exact
                    path="/Home/Notificacoes"
                    component={Notificacoes}
                  />
                  {/* <PrivateRoute
                    exact
                    path="/Home/Conquistas"
                    component={Conquistas}
                  />
                  <PrivateRoute
                    exact
                    path="/Home/Ranking"
                    component={Ranking}
                  /> */}
                  <PrivateRoute exact path="/Pacientes" component={Pacientes} />
                  <PrivateRoute
                    exact
                    path="/Pacientes/Perfil-Paciente"
                    component={PerfilPaciente}
                  />
                  <PrivateRoute
                    exact
                    path="/MinhaConta"
                    component={MinhaConta}
                  />
                  <PrivateRoute exact path="/Descubra" component={Descubra} />
                  <PrivateRoute
                    exact
                    path="/Descubra/Comunicados"
                    component={Comunicados}
                  />
                  <PrivateRoute
                    exact
                    path="/Descubra/Protocolos"
                    component={Protocolos}
                  />
                  <PrivateRoute
                    exact
                    path="/Descubra/Bundle"
                    component={Bundle}
                  />
                  <PrivateRoute
                    exact
                    path="/Descubra/Detalhes"
                    component={Detalhes}
                  />
                  <PrivateRoute
                    exact
                    path="/Relatorios"
                    component={Relatorios}
                  />
                  <PrivateRoute
                    exact
                    path="/Pacientes/Perfil-Paciente/Lesoes/Evolucao"
                    component={EvolucaoLesao}
                  />
                  <PrivateRoute
                    exact
                    path="/Otp/Cadastro"
                    component={CadastrarOtp}
                  />
                  <PrivateRoute
                    exact
                    path="/Otp/Validador"
                    component={ValidarOtp}
                  />
                  <PrivateRoute
                    exact
                    path="/Planos/Selecionar"
                    component={Selecionar}
                  />
                </Switch>
              </main>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </SimpleReactLightbox>
    </div>
  );
}

if (window.Cypress) {
  window.store = store;
}

export default Routes;
