import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Spinner } from "react-bootstrap";
import PwStrengthMeter from "../../../components/PwStrengthMeter";
import { useDispatch } from "react-redux";

import Icon from "../../../functions/Icon";
import Colors from "../../../styles/Colors";
import "../EsqueceuSenha/styles.css";
import api from "../../../services/api";
import { Show_Alert } from "../../../store/actions/alert";
import { User_Login } from "../../../store/actions/user";
import { SaveToken } from "../../../functions/storage";

function AtivarConta({ history }) {
  const dispatch = useDispatch();
  let hash = history?.location?.pathname?.split("/")[2];
  const [showPwd, setShowPwd] = useState(false);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [levelPwd, setLevelPwd] = useState("");
  const [showNewPwd, setShowNewPwd] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState("none");
  const [msgError, setMsgError] = useState("");

  useEffect(() => {
    const pathParts = history.location.pathname.split("/");
    const activationToken = pathParts[2];
    if (activationToken) {
      sessionStorage.setItem(
        "activationToken",
        decodeURIComponent(activationToken)
      );
      history.push({ pathname: "/AtivarConta" });
    } else if (!sessionStorage.getItem("activationToken")) {
      history.push({ pathname: "/" });
    }
  }, [history]);

  function AtivarConta() {
    setMsgError("");
    if (name == "" || password == "" || passwordConfirm == "") {
      setMsgError("Preencha todos os campos");
      return false;
    }
    if (password != passwordConfirm) {
      setMsgError("As senhas não são iguais");
      return false;
    }

    setLoadingBtn("flex");
    api
      .post(`/users/activate`, {
        name,
        password,
        activationToken: sessionStorage.getItem("activationToken"),
      })
      .then((response) => {
        if (response.data) {
          redirectToHome(response.data);
        }
      })
      .catch((error) => {
        console.log("AtivarConta error " + error);
        let errorMsg = "Não foi possível ativar a conta. Contate o suporte.";
        if (error.response.status == 404) {
          errorMsg = "Conta não encontrada.";
        }

        dispatch(
          Show_Alert({
            type: "error",
            msg: errorMsg,
          })
        );
      })
      .finally(() => setLoadingBtn("none"));
  }

  function redirectToHome(data) {
    SaveToken(data.token);
    var dados = {
      id: data.user.id,
      name: data.user.name,
      email: data.user.email,
      cpf: data.user.cpf,
      permission_id: data.user.permission_id,
      permission: data.user.permission,
      image: data.user.image,
      url_image: data.user.url_image,
      gender: data.user.gender,
      date_birth: data.user.date_birth,
      unit_id: data.user.unit_id,
      units: data.user.units,
      qualification_id: data.user.qualification_id,
      professional_record: data.user.professional_record,
      type_workplace_id: data.user.type_workplace_id,
      hospital_id: data.user.hospital_id,
      city: data.user.city,
      is_mfa_enabled: data.user.is_mfa_enabled,
      is_mfa_mandatory: data.user.is_mfa_mandatory,
    };
    dispatch(User_Login(dados));
    const hospitalPlan = data.user.hospital?.plan?.planData;
    dispatch({
      type: "SET_HOSPITAL_PLAN",
      dados: hospitalPlan ? hospitalPlan : 1,
    });
    dispatch(
      Show_Alert({
        type: "success",
        msg: "Conta ativada com sucesso!",
      })
    );
    history.push("/Home");
  }

  function VerifyFields() {
    if (levelPwd >= 3 && name.length >= 4 && password == passwordConfirm) {
      return true;
    }
    return false;
  }

  return (
    <Container fluid className="container-esqueceu">
      <div className="box-esqueceu">
        <h5 className="text-left title mt-3">Ativar conta</h5>
        <h6 className="text-left sm-title">
          Preencha os dados para ativar sua conta.
        </h6>

        <Form className="w-100">
          <Form.Row>
            <Col xs={12} md={12} className="px-0">
              <Form.Group>
                <Form.Label className="field-label">Nome</Form.Label>
                <div className="fake-input">
                  <Form.Control
                    placeholder="Digite seu nome"
                    className="borderless-input"
                    type="text"
                    onChange={(v) => {
                      setMsgError("");
                      setName(v.target.value);
                    }}
                  />
                </div>
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col xs={12} md={12} className="px-0">
              <Form.Group>
                <Form.Label className="field-label">Senha</Form.Label>
                <div className="fake-input">
                  <Form.Control
                    placeholder="Digite uma senha"
                    className="borderless-input"
                    type={showPwd ? "text" : "password"}
                    onChange={(v) => {
                      setMsgError("");
                      setPassword(v.target.value);
                    }}
                  />
                  <div onClick={() => setShowPwd(!showPwd)}>
                    <Icon
                      color={Colors.darker_gray}
                      size={24}
                      icon={showPwd ? "eye-off" : "eye-on"}
                      className="mr-3"
                    />
                  </div>
                </div>
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Row className="mb-0">
            <Col xs={12} md={12} className="px-0">
              <Form.Group>
                <Form.Label className="field-label">Confirmar senha</Form.Label>
                <div className="fake-input">
                  <Form.Control
                    placeholder="Repita a senha"
                    className="borderless-input"
                    type={showNewPwd ? "text" : "password"}
                    onChange={(v) => {
                      setMsgError("");
                      setPasswordConfirm(v.target.value);
                    }}
                  />
                  <div onClick={() => setShowNewPwd(!showNewPwd)}>
                    <Icon
                      color={Colors.darker_gray}
                      size={24}
                      icon={showNewPwd ? "eye-off" : "eye-on"}
                      className="mr-3"
                    />
                  </div>
                </div>
                <PwStrengthMeter value={password} handler={setLevelPwd} />
              </Form.Group>
            </Col>
          </Form.Row>
        </Form>

        {msgError != "" && <div className="invalid-msg">{msgError}</div>}

        <button
          disabled={!VerifyFields()}
          className={`default-btn text-center mt-4 ${
            !VerifyFields() ? "disabled-btn" : ""
          }`}
          onClick={(e) => {
            if (loadingBtn === "flex") {
              e.preventDefault();
            } else {
              AtivarConta();
            }
          }}
        >
          <p
            style={{
              color: VerifyFields() ? "white" : Colors.dark_gray,
              display: loadingBtn == "none" ? "flex" : "none",
              margin: 0,
            }}
          >
            Ativar conta
          </p>

          <Spinner
            style={{ display: loadingBtn }}
            animation="border"
            role="status"
            variant="light"
            size="sm"
          />
        </button>
      </div>
    </Container>
  );
}

export default AtivarConta;
