import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Spinner } from "react-bootstrap";

import api from "../../../services/api";
import "./styles.css";
import logo from "../../../assets/img/logo.png";
import Colors from "../../../styles/Colors";
import Icon from "../../../functions/Icon";
import { User_Login } from "../../../store/actions/user";
import { Show_Alert } from "../../../store/actions/alert";

function Selecionar({ history }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [waitingPayment, setWaitingPayment] = useState(false);

  useEffect(() => {
    checkPaymentStatus();
  }, []);

  function redirectToHome(data) {
    if (data.activationToken) {
      sessionStorage.setItem(
        "activationToken",
        decodeURIComponent(data.activationToken)
      );
      history.push("/AtivarConta");
      return;
    }
    var dados = {
      id: data.user.id,
      name: data.user.name,
      email: data.user.email,
      cpf: data.user.cpf,
      permission_id: data.user.permission_id,
      permission: data.user.permission,
      image: data.user.image,
      url_image: data.user.url_image,
      gender: data.user.gender,
      date_birth: data.user.date_birth,
      unit_id: data.user.unit_id,
      units: data.user.units,
      qualification_id: data.user.qualification_id,
      professional_record: data.user.professional_record,
      type_workplace_id: data.user.type_workplace_id,
      hospital_id: data.user.hospital_id,
      city: data.user.city,
      is_mfa_enabled: data.user.is_mfa_enabled,
      is_mfa_mandatory: data.user.is_mfa_mandatory,
    };
    dispatch(User_Login(dados));
    const hospitalPlan = data.user.hospital?.plan?.planData;
    dispatch({
      type: "SET_HOSPITAL_PLAN",
      dados: hospitalPlan ? hospitalPlan : 1,
    });
    history.push("/Home");
  }

  function SubscribeToPlan(planType) {
    setLoading(true);
    api
      .post("/plans/subscribe", { planType })
      .then((response) => {
        if (response.data && response.data.paymentUrl) {
          window.open(response.data.paymentUrl, "_blank");
          setWaitingPayment(true);
          checkPaymentStatus();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function checkPaymentStatus() {
    api
      .get("/plans/status")
      .then((response) => {
        if (response.data && response.data.status) {
          setWaitingPayment(false);
          redirectToHome(response.data);
        } else {
          setTimeout(checkPaymentStatus, 1000);
        }
      })
      .catch((error) => {
        setTimeout(checkPaymentStatus, 1000);
      });
  }

  return (
    <div className="full-height-container">
      <Container
        fluid
        className="main-container d-flex justify-content-center align-items-center container-otp"
      >
        <div className="mb-3">
          <img
            className="responsive-logo"
            src={
              "https://preveni.blob.core.windows.net/images/preveni-notify.png"
            }
          />
        </div>
        <Row className="justify-content-center">
          {loading ? (
            <div className="card-report d-flex flex-column justify-content-center align-items-center">
              <Spinner animation="border" variant="primary" />
              <p className="mt-3">Processando...</p>
            </div>
          ) : waitingPayment ? (
            <div className="card-report d-flex flex-column justify-content-center align-items-center">
              <Spinner animation="border" variant="primary" />
              <p className="mt-3">Aguardando confirmação de pagamento...</p>
            </div>
          ) : (
            <>
              <Col md={4}>
                <div
                  className="card-report mb-3"
                  style={{
                    padding: "32px",
                  }}
                >
                  <h3 className="plan-name mb-3">Inicial</h3>
                  <div className="d-flex align-items-center mb-2">
                    <p className="plan-price">R$30</p>
                    <p className="plan-range">por mês</p>
                  </div>
                  <div>
                    <p className="plan-f-message">
                      Tudo o que você precisa para começar:
                    </p>
                    <div>
                      <p className="plan-item-content">
                        <span>
                          <Icon
                            icon="check"
                            size={20}
                            color={Colors.dark_blue}
                            className="mr-2"
                          />
                          10 pacientes
                        </span>
                      </p>
                      <p className="plan-item-content">
                        <span>
                          <Icon icon="check" size={20} className="mr-2" />
                          Ilimitados registros de lesões{" "}
                        </span>
                      </p>
                      <p className="plan-item-content">
                        <span>
                          <Icon icon="check" size={20} className="mr-2" />
                          Todos relatórios, análises e indicadores disponíveis{" "}
                        </span>
                      </p>
                      <p className="plan-item-content">
                        <span>
                          <Icon icon="check" size={20} className="mr-2" />
                          Suporte 24/7{" "}
                        </span>
                      </p>
                      <p className="plan-item-content">
                        <span>
                          <Icon icon="check" size={20} className="mr-2" />E
                          muito mais!{" "}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        SubscribeToPlan("Inicial");
                      }}
                      className="mb-0 modal-footer-btn btn-confirm"
                      style={{
                        backgroundColor: Colors.brand_pink,
                      }}
                    >
                      <p
                        className="mb-0"
                        style={{
                          color: "white",
                          display: "flex",
                        }}
                      >
                        Assinar
                      </p>
                    </button>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div
                  className="card-report mb-3"
                  style={{
                    padding: "32px",
                  }}
                >
                  <h3 className="plan-name mb-3">Intermediário</h3>
                  <div className="d-flex align-items-center mb-2">
                    <p className="plan-price">R$50</p>
                    <p className="plan-range">por mês</p>
                  </div>
                  <div>
                    <p className="plan-f-message">
                      Tudo o que você precisa para começar:
                    </p>
                    <div>
                      <p className="plan-item-content">
                        <span>
                          <Icon
                            icon="check"
                            size={20}
                            color={Colors.dark_blue}
                            className="mr-2"
                          />
                          20 pacientes
                        </span>
                      </p>
                      <p className="plan-item-content">
                        <span>
                          <Icon icon="check" size={20} className="mr-2" />
                          Ilimitados registros de lesões{" "}
                        </span>
                      </p>
                      <p className="plan-item-content">
                        <span>
                          <Icon icon="check" size={20} className="mr-2" />
                          Todos relatórios, análises e indicadores disponíveis{" "}
                        </span>
                      </p>
                      <p className="plan-item-content">
                        <span>
                          <Icon icon="check" size={20} className="mr-2" />
                          Suporte 24/7{" "}
                        </span>
                      </p>
                      <p className="plan-item-content">
                        <span>
                          <Icon icon="check" size={20} className="mr-2" />E
                          muito mais!{" "}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        SubscribeToPlan("Intermediário");
                      }}
                      className="mb-0 modal-footer-btn btn-confirm"
                      style={{
                        backgroundColor: Colors.brand_pink,
                      }}
                    >
                      <p
                        className="mb-0"
                        style={{
                          color: "white",
                          display: "flex",
                        }}
                      >
                        Assinar
                      </p>
                    </button>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div
                  className="card-report"
                  style={{
                    padding: "32px",
                  }}
                >
                  <h3 className="plan-name mb-3">Empresarial</h3>
                  <div className="d-flex align-items-center mb-2">
                    <p className="plan-price">R$70</p>
                    <p className="plan-range">por mês</p>
                  </div>
                  <div>
                    <p className="plan-f-message">
                      Tudo o que você precisa para começar:
                    </p>
                    <div>
                      <p className="plan-item-content">
                        <span>
                          <Icon
                            icon="check"
                            size={20}
                            color={Colors.dark_blue}
                            className="mr-2"
                          />
                          30 pacientes
                        </span>
                      </p>
                      <p className="plan-item-content">
                        <span>
                          <Icon icon="check" size={20} className="mr-2" />
                          Ilimitados registros de lesões{" "}
                        </span>
                      </p>
                      <p className="plan-item-content">
                        <span>
                          <Icon icon="check" size={20} className="mr-2" />
                          Todos relatórios, análises e indicadores disponíveis{" "}
                        </span>
                      </p>
                      <p className="plan-item-content">
                        <span>
                          <Icon icon="check" size={20} className="mr-2" />
                          Suporte 24/7{" "}
                        </span>
                      </p>
                      <p className="plan-item-content">
                        <span>
                          <Icon icon="check" size={20} className="mr-2" />E
                          muito mais!{" "}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        SubscribeToPlan("Empresarial");
                      }}
                      className="mb-0 modal-footer-btn btn-confirm"
                      style={{
                        backgroundColor: Colors.brand_pink,
                      }}
                    >
                      <p
                        className="mb-0"
                        style={{
                          color: "white",
                          display: "flex",
                        }}
                      >
                        Assinar
                      </p>
                    </button>
                  </div>
                </div>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default Selecionar;
